import React from "react"
import Banner from "../../components/Hyderabad/Banner"
import Layout from "../../components/layout"
import Description from "../../components/Hyderabad/Description"
import ButtonLinks from "../../components/Hyderabad/ButtonLinks"
import Faqs from "../../components/Hyderabad/Faqs"
import OurTrustedPartners from "../../components/about/OurTrustedPartners"
import bgImage from "../../../static/assets/hyderabad/e-commerce-development.svg"
import data from "../../content/hyderabad/Description/ECommerceDevelopment.json"
import faqs from "../../content/hyderabad/Faqs/ecommercedevelopment.json"
import SEO from "../../components/seo"

const ECommerceDevelopment = () => {
  const keywords = [
    "E-commerce Development services in hyderabad",
    "best E-commerce Development  services  company",
    "E-commerce Development services ",
    "E-commerce Development  services  in Hyderabad ",
    "E-commerce Development  services  in Hyderabad",
  ]
  return (
    <Layout className="page">
      <SEO
        title="Cresol Infoserv | Best Ecommerce Development Services in hyderabad"
        description="Specializes in custom eCommerce website development and design, focusing on creating user-friendly and visually appealing online stores. With Cresol infoserv."
        keywords={keywords}
      />
      <Banner
        heading="Best E-commerce services In Hyderabad"
        bgImage={bgImage}
      />
      <Description data={data} />
      <ButtonLinks />
      <Faqs faqs={faqs} />
      <OurTrustedPartners />
    </Layout>
  )
}

export default ECommerceDevelopment
